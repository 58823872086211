import { api } from '.';
import { CAMPAIGNS_RESOURCE, FINISH_RESOURCE } from '../../../keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';

export const getResources = campaignId => ({
  [CAMPAIGNS_RESOURCE]: campaignId,
  [FINISH_RESOURCE]: undefined,
});

/**
 *
 * @param {string} campaignId
 * @returns {Promise.<ResponseBuilder>}
 */
export async function finishCampaign(campaignId) {
  const partialUrl = api.createUrl(getResources(campaignId));
  const {
    data: {
      campaign: { id },
    },
  } = await api.update(partialUrl);

  return new ResponseBuilder(id);
}
