/// <reference path="../../../../entities/socialAudience/Campaign.js" />

import { CAMPAIGNS_RESOURCE } from '../../../keys';
import { api } from '.';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import socialAudienceEntityParser from '@/entities/socialAudience/SocialAudienceEntityParser';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

/**
 * @param {string} campaignId
 */
export const getResources = campaignId => ({
  [CAMPAIGNS_RESOURCE]: campaignId,
});

/**
 * @param {string} campaignId
 * @returns {Promise.<ResponseBuilder>}
 */
export async function getCampaignById(campaignId) {
  const partialUrl = api.createUrl(getResources(campaignId));
  const params = new QueryParamsBuilder();
  params.addInclude('advertiser');

  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(socialAudienceEntityParser.parse(data), data.meta).build();
}
