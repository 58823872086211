/// <reference path="../../../../entities/socialAudience/Campaign.js" />
import { updateCampaignShared } from '@/services/shared/campaign';
import { api } from '.';
import ResponseBuilder from '@/model/shared/ResponseBuilder';

/**
 *
 * @param {Campaign} campaign
 * @returns {Promise.<ResponseBuilder>}
 */
export async function updateCampaign(campaign) {
  const {
    data: {
      campaign: { id },
    },
  } = await updateCampaignShared(api, campaign);
  return new ResponseBuilder(id);
}
