import { api } from '.';
import { CAMPAIGNS_RESOURCE, PUT_ONLINE_RESOURCE } from '../../../keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';

export const getResources = campaignId => ({
  [CAMPAIGNS_RESOURCE]: campaignId,
  [PUT_ONLINE_RESOURCE]: undefined,
});

/**
 *
 * @param {string} campaignId
 * @returns {Promise.<ResponseBuilder>}
 */
export async function putOnlineCampaign(campaignId) {
  const partialUrl = api.createUrl(getResources(campaignId));
  const {
    data: {
      campaign: { id },
    },
  } = await api.update(partialUrl);
  return new ResponseBuilder(id);
}
