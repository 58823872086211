import { CAMPAIGNS_RESOURCE, CLIENT_RESOURCE } from '../../../keys';
import { api } from '.';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import socialAudienceEntityParser from '@/entities/socialAudience/SocialAudienceEntityParser';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

/**
 * @param {string} clientId
 * @param {string} campaignId
 */
export const getResources = clientId => ({
  [CLIENT_RESOURCE]: clientId,
  [CAMPAIGNS_RESOURCE]: undefined,
});

/**
 *
 * @param {string} clientId
 * @returns {Promise.<ResponseBuilder>}
 */
export async function getCampaigns(params = new QueryParamsBuilder(), clientId) {
  const partialUrl = api.createUrl(getResources(clientId));
  params.addInclude('advertiser', 'platform');

  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(socialAudienceEntityParser.parse(data), data.meta).build();
}
