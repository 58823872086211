import { socialAudience } from '..';
import { createCampaign } from './createCampaign';
import { deleteCampaign } from './deleteCampaign';
import { updateCampaign } from './updateCampaign';
import { getCampaigns } from './getCampaigns';
import { getCampaignById } from './getCampaignById';
import { putOnlineCampaign } from './putOnlineCampaign';
import { pauseCampaign } from './pauseCampaign';
import { finishCampaign } from './finishCampaign';

export const api = socialAudience;

export {
  createCampaign,
  deleteCampaign,
  updateCampaign,
  getCampaigns,
  getCampaignById,
  putOnlineCampaign,
  pauseCampaign,
  finishCampaign,
};
